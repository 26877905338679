<template>
    <!--todo 弹框功能的多层跳转-->
    <div class="SysOutDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" label-width="80px" width="auto" :inline="true" size="small">
                <el-form-item label="机构名称">
                    <el-input v-model="form.deptName" readonly />
                </el-form-item>
                <el-form-item label="入库单号">
                    <el-input v-model="form.sysInCode" readonly />
                </el-form-item>
                <el-form-item />
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleAudit" v-if="auditDetail" size="small">审核详情</el-button>

            <PickupReturnAudit ref="PickupReturnAudit" :review-privilege-flag="'menu.purchase.sysOut.review'" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
            >
                <el-table-column label="序号" type="index" width="45" align="center" fixed="left" />
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.type" label="类型" width="80">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sku.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="fastBar" label="助记码" width="120" />
                <el-table-column prop="customBar" label="自编码" width="120" />
                <el-table-column prop="sku.specs" label="规格" width="120" />
                <el-table-column prop="sku.category" label="类目" width="120" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="150" />
                <el-table-column prop="counts" label="出库数量" width="100" />
                <el-table-column prop="wholeSalePrice" label="批发价" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.wholeSalePrices | money }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="retailPrice" label="零售价" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.retailPrices | money }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import MoneyUtils from '../../../../js/MoneyUtils';
import UrlUtils from '../../../../js/UrlUtils';
import PickupReturnAudit from '../../../components/PickupReturnAudit';

export default {
    name: 'SysOutDetail',
    props: ['form'],
    components: { PickupReturnAudit },
    data() {
        return {
            baseUrl: '/purchase/sysOut',
            url: {
                page: '/goods/goods/list_c',
            },
            codes: [],
            auditDetail: false,
            priceAuditDialogTable: false,
            reviewListData: [],
            tableData: [],
        };
    },
    mounted() {
        this.codes = this.form.goodsCodes;
        this.handleQuery();
        UrlUtils.QueryRemote(this, `${this.baseUrl}/reviewListData/` + this.form.code, (rst) => {
            if (rst.reviewList.length > 0) {
                this.auditDetail = true;
            }
        });
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl, this.form.stockStatus);
        },
        handleQuery() {
            const _this = this;
            const params = {
                params: _this.codes,
                paramsSerializer: function (data) {
                    return data.map((d) => 'codes=' + d).join('&');
                },
            };
            Util.queryTable(_this, _this.url.page, params, (rst) => {
                rst.data.forEach((v, i) => {
                    v.counts = this.form.counts[i];
                    v.wholeSalePrices = this.form.wholeSalePrices[i];
                    v.retailPrices = this.form.retailPrices[i];
                });
                this.tableData = rst.data;
            });
        },
    },
    filters: {
        money(money) {
            if (typeof money != 'undefined') {
                return MoneyUtils.formatMoney(MoneyUtils.moneyToYuan(money));
            }
            return '';
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type == '0') {
                return '标品';
            }
            if (type == '1') {
                return '非标品';
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
